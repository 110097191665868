<template>
  <div class="contact_banner">
    <div class="banner_info">
      <h3>广为科技</h3>
      <p>客户的微笑是我们的服务目标</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactBanner",
};
</script>

<style lang="less" scoped>
.contact_banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 400px;
  background: url("~@/assets/img/banner/contact_banner.jpg") no-repeat
    center/cover;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(25, 27, 73, 0.5);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: url("~@/assets/img/product/product_banner_bg.png") repeat;
  }
}
.banner_info {
  margin-bottom: 40px;
  position: absolute;
  color: #fff;
  z-index: 20;
  text-align: center;
  h3 {
    font-size: 60px;
    font-weight: 700;
  }
  p {
    font-size: 45px;
  }
}
</style>