<template>
  <div>
    <!-- 联系我们banner组件 -->
    <contact-banner></contact-banner>
    <div class="contact">
      <div class="contact_title">
        <h3>联系我们</h3>
        <p>contact us</p>
      </div>
      <div class="desc">
        <!-- 百度地图组件 -->
        <baidu-map id="allmap" @ready="mapReady" :scroll-wheel-zoom="true">
          <!-- bm-marker 就是标注点 定位在point的经纬度上 跳动的动画 -->
          <bm-marker :position="point" @click="infoWindowOpen" :dragging="true">
            <bm-label
              content="广为数盈信息科技有限公司"
              :labelStyle="{
                color: '#333',
                fontSize: '16px',
                borderColor: '#333',
              }"
              :offset="{ width: -80, height: 28 }"
            />
          </bm-marker>
        </baidu-map>
        <!-- 描述信息 -->
        <div class="company_profile">
          <h2>合肥广为数盈信息科技有限公司</h2>
          <h3>广为科技竭诚为您提供全方位的专业服务</h3>
          <div class="item_info">
            <div class="inner">
              <div class="text">
                <h4>咨询热线</h4>
                <!-- <p><a href="tel:18019568711">180 1956 8711</a></p>
                <p><a href="tel:17755102901">177 5510 2901</a></p> -->
                <p>
                  <a href="tel:0551-63829863">0551-63829863</a>
                </p>
              </div>
            </div>
            <div class="inner">
              <div class="text">
                <h4>服务邮箱</h4>
                <p>xyl@hfgw.info</p>
              </div>
            </div>
            <div class="inner">
              <div class="text">
                <h4>企业地址</h4>
                <p>合肥市高新区科学大道118号<br />5F创业园 A栋211室</p>
              </div>
            </div>
            <div class="inner">
              <div class="text">
                <h4>关注我们</h4>
              </div>
            </div>
            <div class="code"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactBanner from "./components/contact-banner.vue";
export default {
  components: { contactBanner },
  name: "Contact",
  data() {
    return {
      point: "",
      show: true,
    };
  },
  methods: {
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(117.217041, 31.860239);
      map.centerAndZoom(this.point, 15);
    },
  },
};
</script>

<style lang="less" scoped>
.contact {
  padding: 50px 30px;
  text-align: center;
  .contact_title {
    h3 {
      font-size: 50px;
      font-weight: 700;
      color: #302874;
    }
    p {
      font-size: 25px;
      color: #4fbdf6;
      text-transform: uppercase;
      margin-top: 0;
    }
  }
  .desc {
    #allmap {
      margin-top: 40px;
      width: 100%;
      height: 450px;
      border: 1px solid #000;
    }
    #maptips {
      margin-top: 20px;
      font-size: 35px;
    }
    /deep/.anchorBL {
      display: none;
    }
    .company_profile {
      padding: 40px 30px;
      text-align: left;
      h2 {
        font-size: 40px;
        color: #302874;

        font-weight: 700;
      }
      h3 {
        margin-top: 30px;
        font-size: 35px;
        color: #302874;
      }
      .item_info {
        width: 100%;
        .inner {
          display: flex;
          align-items: center;
          padding: 20px 0;
          margin-top: 20px;
          &::before {
            width: 100px;
            height: 100px;
            border-radius: 50px;
            line-height: 100px;
            font-size: 70px;
            text-align: center;
            font-family: "iconfont";
            color: #fff;
            background-color: #302874;
            margin-right: 40px;
          }
          .text {
            display: flex;
            flex-direction: column;
          }
          &:nth-child(1) {
            &::before {
              content: "\e61b";
            }
            p {
              line-height: 1.2;
              a {
                color: #302874;
              }
            }
          }
          &:nth-child(2) {
            &::before {
              content: "\e605";
            }
          }
          &:nth-child(3) {
            &::before {
              content: "\e60d";
            }
          }
          &:nth-child(4) {
            &::before {
              content: "\e7ad";
            }
            .text {
              padding-top: 30px;
            }
            padding-bottom: 0;
          }
        }
        .code {
          width: 300px;
          height: 300px;
          margin-left: 140px;
          border: 1px solid #000;
          background: url("~@/assets/img/code.jpg") no-repeat center / cover;
        }
        h4 {
          font-size: 35px;
          color: #302874;
        }
        p {
          font-size: 30px;
          color: #302874;
        }
      }
    }
  }
}
</style>